function MainButton(props) {

  const clicked = () => {
    props.callback();
  }

  return (
    <div className="MainButton" onClick={() => clicked()}>
      <h2 style={{ fontSize: "16px" }} className="text-center">{props.caption}</h2>
      <div className="text-center">
        <img className="MainImg" src={props.img} />
      </div>
    </div>
  )

}

export default MainButton;