import swal from 'sweetalert';

export function showError(errorText) {

  swal({
    title: 'Oops',
    text: errorText,
    icon: 'error',
    className: 'error-modal',
    confirmButtonColor: '#67DD67',
    confirmButtonText: 'Got it'
  })

}

export function showInfo(infoText) {

  swal({
    title: 'Information',
    text: infoText,
    icon: 'info',
    className: 'info-modal',
    confirmButtonColor: '#DD6767',
    confirmButtonText: 'Understood'
  })

}

export function showYesNoQuestion(questionText, confirmCallback) {
    
  swal({
    title: 'Please confirm',
    text: questionText,
    icon: 'info',
    className: 'question-modal',
    confirmButtonColor: '#DD6767',
    cancelButtonColor: '#DD6767',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    buttons: {
      cancel: true,
      confirm: true
      }
  }).then((confirm) => {
    if (confirm) {
      console.log('b')
      confirmCallback()
    }
  })


}