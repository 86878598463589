function TextButton(props) {

  if (props.DivId == null) {
    return (
      <div className={props.IsCancel != null && props.IsCancel == true ? "cancelbtn" : props.IsSuccess != null && props.IsSuccess == true ? "successbtn" : "btn"} style={{ width: props.Width }} onClick={props.Callback}>
        {props.Caption}
      </div>
    )
  }
  else {
    return (
      <div id={props.DivId} className={props.IsCancel != null && props.IsCancel == true ? "cancelbtn" : props.IsSuccess != null && props.IsSuccess == true ? "successbtn" : "btn"} style={{ width: props.Width }} onClick={props.Callback}>
        {props.Caption}
      </div>
    )
  }

}

export default TextButton;