import React, { useState, useReducer, useEffect } from 'react';
import { showError, showInfo } from '../components/PopupMessages.js';
import { postAndWait } from '../backend_access/APIAccess.js';
import TextButton from '../components/TextButton.js';

function PartialStocktakeLocationPicker(props) {

  const [locationFilter, setLocationFilter] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [pickedList, setPickedList] = useState([])
  const [allLocationsInArea, setAllLocationsInArea] = useState([])
  const [multiAssignText, setMultiAssignText] = useState('')

  const handleFilterChange = (event) => {
    var newVal = event.target.value
    setLocationFilter(newVal)
    if (newVal == '') {
      setFilteredList(allLocationsInArea);
      setMultiAssignText('Assign ' + allLocationsInArea.length + ' locs. to stocktack')
    }
    else {
      var filt = []
      for (var nn = 0; nn < allLocationsInArea.length; nn++) {
        if (allLocationsInArea[nn].Name.toUpperCase().includes(newVal.toUpperCase())) {
          filt.push(allLocationsInArea[nn])
        }
      }
      setFilteredList(filt);
      setMultiAssignText('Assign ' + filt.length + ' locs. to stocktack')
    }
  }

  const pickSomeLocations = () => {
    var pic = pickedList;
    for (var nn = 0; nn < filteredList.length; nn++) {
      pic.push(filteredList[nn])
    }
    setPickedList(pic)
  }

  const closeWithoutSelecting = () => {
    props.ClosePickScreenNoAction()
  }

  const closeWithSelecting = () => {
    window.state.locationsSelected = pickedList
    window.state.modalConfirmAction()
    props.ClosePickScreenNoAction()

  }

  const addLocationThroughClick = (loc) => {
    var pic = JSON.parse(JSON.stringify(pickedList))
    let isDup = false;
    for (var nn = 0; nn < pic.length; nn++) {
      if (pic[nn].Id == loc.Id) {
        isDup = true;
        break;
      }
    }
    if (!isDup) {
      pic.push(loc)
    }
    setPickedList(pic)
  }

  const removeLocationThroughClick = (sas3Id) => {
    var pic = []
    for (var nn = 0; nn < pickedList.length; nn++) {
      if (pickedList[nn].Id != sas3Id) {
        pic.push(pickedList[nn])
      }
    }
    setPickedList(pic)
  }

  if (allLocationsInArea.length == 0) {
    var request = {}
    request.Token = window.state.apiToken;
    request.Id = props.AreaId

    var resp = postAndWait('GetLocationsByArea', request);
    if (resp.Result) {
      setAllLocationsInArea(resp.Data);
      setFilteredList(resp.Data);
      setMultiAssignText('Assign ' + resp.Data.length + ' locs. to stocktack')
    }
  }

  return (
    <table width="600px">
      <tr>
        <td style={{ verticalAlign: 'top', width: '300px' }}>
          <h2>All locations</h2>
          Filter: <input className="TextBox Narrow" id="txtFilt" onChange={handleFilterChange} />
          <div className="PickerLocationList" style={{ width: '390px', minWidth: '390px', maxWidth: '390px' }}>
            {filteredList.map((f) => <span onClick={() => addLocationThroughClick(f)} className="LocationToAssign">{f.Name}</span>)}
          </div>
        </td>
        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
        <td style={{ verticalAlign: 'top' }}>
          <h2>Selected for the stocktake</h2>
          <span className="FilterListCount">(Currently: {pickedList.length} locations)</span>
          <div className="PickerLocationList" style={{ width: '390px', minWidth: '390px', maxWidth: '390px' }}>
            {pickedList.map((f) => <span onClick={() => removeLocationThroughClick(f.Id)} className="LocationAssigned">{f.Name}&nbsp; </span>)}
          </div>
        </td>
      </tr>
      <tr>
        <td style={{ paddingTop: '12px' }}><TextButton Width={300} Caption={multiAssignText} Callback={pickSomeLocations} /><br /><TextButton Callback={closeWithoutSelecting} Width={300} IsCancel={true} Caption='Go back to previous screen' /></td>
        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
        <td style={{ verticalAlign: 'top', paddingTop: '12px' }}><TextButton Callback={closeWithSelecting} IsSuccess={pickedList.length > 0} IsCancel={pickedList.length == 0} Width={300} Caption={pickedList.length + ' locations. Proceed!'} /></td>
      </tr>
    </table>
  )

}

export default PartialStocktakeLocationPicker;