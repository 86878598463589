var urlBase = process.env.URL_BASE || 'https://dev.stocktake.storm.tech/api/StockTake/';

export function postAndWait(func, obj) {

  var jsonString = JSON.stringify(obj);
  var poster = new XMLHttpRequest();
  poster.open('POST', urlBase + func, false);
  poster.setRequestHeader("Content-Type", "application/json");
  poster.send(jsonString);
  var resp = JSON.parse(poster.responseText);
  return resp;

}

export function postAsync(func, obj, callback) {

  var jsonString = JSON.stringify(obj);
  var poster = new XMLHttpRequest();
  poster.open('POST', urlBase + func, true);
  poster.setRequestHeader("Content-Type", "application/json");
  poster.addEventListener("load", callback);
  poster.send(jsonString);


}

export function convertAsyncResponseToObject(resp) {
  return JSON.parse(resp.target.response);
}

export function processAPIResponse(obj) {
  if (!obj.Result) {
    alert(obj.ErrorCode + ': ' + obj.ErrorMessage);
  }
  return obj.Result;
}