import React, { useState, useReducer, useEffect, useInsertionEffect } from 'react';
import HumanInput from 'humaninput';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import { showError, showInfo, showYesNoQuestion } from '../components/PopupMessages.js';
import TextButton from '../components/TextButton.js';
function SetMissingItemLocation(props) {

  const [scanError, setScanError] = useState('');
  const [updater, forceUpdate] = useReducer(x => x + 1, 0);
  const [lastSubmittedId, setLastSubmittedId] = useState(0);

  const submitLocationOnEnter = () => {

    if (window.state.locationLookupInProgress) {
      return;
    }

    if (window.state.scanScreenInput == '') {
      return;
    }

    window.state.locationLookupInProgress = true;

    var req = {}
    req.Token = window.state.apiToken;
    req.Id = window.state.scanScreenInput; // document.getElementById('LocationBox').value;

    if (req.Id > 2147483647) {
      setScanError('The number represented by that barcode is too big to be a valid SAS3 Location')
      document.getElementById('LocationBox').value = '';
      document.getElementById('LocationBox').focus();
      return;
    }

    setLastSubmittedId(window.state.scanScreenInput)
    postAsync("GetLocationBySASId", req, submitLocationScanReturn)

  }

  const submitLocationScanReturn = (resp) => {

    window.state.locationLookupInProgress = false;
    window.state.itemLookupInProgress = false;
    window.state.scanScreenInput = '';

    var data = convertAsyncResponseToObject(resp);

    if (data.Result == undefined || data.Result == null || data.Result == false) {
      if (data.ErrorMessage == null) {
        showError('Bad request. Are you sure you scanned/typed a location barcode?');
        return;
      }
      if (data.ErrorMessage.includes('is not part of the current')) {
        showError(data.ErrorMessage);
        document.getElementById('LocationBox').value = '';
        document.getElementById('LocationBox').focus();
        return;
      } else {
        showError("An error occurred when accessing the backend of the system. Please report this to development. Error was: " + data.ErrorMessage);
        document.getElementById('LocationBox').value = '';
        document.getElementById('LocationBox').focus();
        return;
      }
    }

    window.state.scanScreenLastLocationName = data.Data

    forceUpdate();

  }

  const enterPressedOnBox = () => {
    submitLocationOnEnter()
  }

  const keyUp = (event, key, code) => {

    if (event.srcElement.id == 'ItemBox') {
      if (code == 'Enter') {
        window.state.scanScreenInput = document.getElementById('ItemBox').value
        enterPressedOnBox();
        return;
      }
    } else if (event.srcElement.id == 'LocationBox') {
      if (code == 'Enter') {
        window.state.scanScreenInput = document.getElementById('LocationBox').value
        enterPressedOnBox();
        return;
      }
    }

    if (code != 'Enter' && (code.length == 1 || code == "Equal")) {
      window.state.scanScreenInput += key;
    }
    if (code == 'Enter') {
      if (document.getElementById('ItemBox') != null && document.getElementById('ItemBoxHider').className == 'Visible') {
        window.state.scanScreenInput = document.getElementById('ItemBox').value
      }
      enterPressedOnBox();
    }

    window.state.scanScreenLastScanTimeout = event.timeStamp

  }

  const confirmLocation = () => {
    window.state.missingItemScanLocation = lastSubmittedId
    window.state.scanScreenLastLocationName = ''
    window.state.modalConfirmAction();
  }

  const askToConfirmFailure = () => {

    showYesNoQuestion('Give up this missing-item hunt? You MUST go and discuss this with the stock take administrator if you click OK', failHunt)

  }

  const failHunt = () => {

    let request = {}
    request.Token = window.state.apiToken;
    request.Id = window.state.missingItemToFind.Id

    postAndWait('ItemHuntUnsuccessful', request);
    props.CloseMissingItemScreenNoAction()

  }



  if (window.state.humanInput == null) {

    eval('window.__VERSION__ = 0')
    var hi = new HumanInput(window)

    hi.off('keydown')
    hi.on('keydown', keyUp)
    hi.filter = (e) => { return true }

    window.state.humanInput = hi;
    window.state.locationLookupInProgress = false;
    window.state.itemLookupInProgress = false;

  }


  return (
    <div style={{ margin: "auto", width: "700px", textAlign: "center", paddingTop: "40px" }}>
      <h3>You are hunting for:</h3>
      <div>{window.state.missingItemToFind.Description}<br /><span style={{ color: "rgb(247 170 73)"} }>SERIAL: </span>{window.state.missingItemToFind.SerialNo}</div>
      <h3>Scan the barcode of the location where this item is</h3>
      <div class="Invisible" id="LocationBoxHider">
        <input autocomplete="off" id="LocationBox" class="TextBox Wide" />
      </div>
      <div class={scanError == '' ? 'Invisible' : 'ScanError'}>
        {scanError}
      </div>
      {window.state.scanScreenLastLocationName != '' ?
        <div>
          <h2>You scanned: <span style={{ fontWeight: "700", color: '#77FF77' }}>{window.state.scanScreenLastLocationName}</span></h2>
          <div style={{ display: "flex", width: "700px", alignItems: "center", justifyContent: "center" }}>
            <TextButton IsSuccess={true} Callback={() => confirmLocation()} Width="350px" Caption="Mark this item as being in this location" />
          </div>
        </div> : <></>}
      <div style={{ paddingTop: "30px", display: "flex", width: "700px", alignItems: "center", justifyContent: "center" }}>
        <TextButton IsCancel={true} Callback={() => askToConfirmFailure()} Width="350px" Caption="I cannot find this item" />
      </div>
      <div style={{ paddingTop: "30px", display: "flex", width: "700px", alignItems: "center", justifyContent: "center" }}>
        <TextButton Callback={() => props.CloseMissingItemScreenNoAction()} Width="350px" Caption="Close, I'll come back later" />
      </div>
    </div>
  );

}

export default SetMissingItemLocation;
