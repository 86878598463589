import React, { useState, useReducer, useEffect } from 'react';
import { showError, showInfo } from '../components/PopupMessages.js';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import { forceImmediateStockTakeInProgressCacheRefresh, forceImmediateStockTakeStatsRefresh, forceImmediateAllLocationsRefresh, forceImmediateStatusRefresh } from '../backend_access/Cache';
import TextButton from '../components/TextButton.js';
import SmallButton from '../components/SmallButton';
function NewStocktake(props) {

  const [newStockTakeLocation, setNewStockTakeLocation] = useState(window.cache.Areas[0].Id);
  const [newStocktakePickedLocs, setNewStocktakePickedLocs] = useState([]);
  const [newStockTakeType, setNewStockTakeType] = useState(1);
  const [newStockTakeCreating, setNewStockTakeCreating] = useState(false);

  const startNewStockTake = () => {

    if (newStocktakePickedLocs.length === 0) {
      if (newStockTakeType === 3 || newStockTakeType === 6) {
        showError('The selected stocktake type requires you to select locations. None are selected right now')
        return;
      }
    }

    var req = {}
    req.Token = window.state.apiToken;
    req.StockTakeName = document.getElementById('txtNewSTName').value;
    req.StockTakeType = newStockTakeType;
    req.AreaId = newStockTakeLocation;

    if (newStockTakeType == 3 || newStockTakeType == 6) {
      var sasLocationList = []
      var sasLocationNameList = []
      for (var nn = 0; nn < newStocktakePickedLocs.length; nn++) {
        sasLocationList.push(newStocktakePickedLocs[nn].Id)
        sasLocationNameList.push(newStocktakePickedLocs[nn].Name)
      }
      req.SpecificLocations = sasLocationList
      req.SpecificLocationNames = sasLocationNameList
    }

    //###TODO: if not full or seed, need to set locations info
    postAsync("AddNewStocktake", req, newStockTakeCreated)
    setNewStockTakeCreating(true)

  }

  const fullAreaClicked = () => {
    document.getElementById('txtNewSTName').value = 'Full stocktake: Warehouse - ' + new Date().toDateString();
  }

  const partialRandomClicked = () => {
    document.getElementById('txtNewSTName').value = 'Partial random: Warehouse - ' + new Date().toDateString();
    setTimeout(setRandomBox, 100)
  }

  const partialSelectedClicked = () => {
    document.getElementById('txtNewSTName').value = 'Partial selected: Warehouse - ' + new Date().toDateString();
  }

  const fullSeedClicked = () => {
    document.getElementById('txtNewSTName').value = 'Seeding full stocktake: Warehouse - ' + new Date().toDateString();
  }

  const partialSeedClicked = () => {
    document.getElementById('txtNewSTName').value = 'Seeding partial random: Warehouse - ' + new Date().toDateString();
  }

  const specificSeedClicked = () => {
    document.getElementById('txtNewSTName').value = 'Seeding partial selected: Warehouse - ' + new Date().toDateString();
  }

  const setRandomBox = () => {
    document.getElementById('txtRandomLocs').value = '50';    
  }

  const newStockTakeCreated = (resp) => {

    var data = convertAsyncResponseToObject(resp);

    if (data.Result == false) {
      showError("An error occurred when accessing the backend of the system. Please report this to development. Error was: " + resp.ErrorMessage);
      return;
    }

    forceImmediateStatusRefresh();
    forceImmediateStockTakeInProgressCacheRefresh();
    forceImmediateStockTakeStatsRefresh();
    forceImmediateAllLocationsRefresh();

    showInfo('Your stocktake is created and ready go!');

    props.ReturnToMain();

  }

  const selectLocations = () => {
    window.state.areaForLocationPick = newStockTakeLocation
    props.ShowLocationPickScreen(locationsPicked)
  }

  const locationsPicked = () => {
    setNewStocktakePickedLocs(window.state.locationsSelected)
  }

  const toggleSelectedArea = (id) => {
    setNewStockTakeLocation(id)
  }

  const toggleSelectedStockTakeType = (id) => {
    setNewStockTakeType(id)
    if (id === 1) {
      fullAreaClicked()
    } else if (id === 2) {
      partialRandomClicked()
    } else if (id === 3) {
      partialSelectedClicked()
    } else if (id === 4) {
      fullSeedClicked()
    } else if (id === 5) {
      partialSeedClicked()
    } else if (id === 6) {
      specificSeedClicked()
    }

  }

  useEffect(() => {
    toggleSelectedStockTakeType(1)
  }, [])

  return (

    <div style={{ marginTop: "-15px", paddingLeft: "20px" }} className="AreaSelectDiv">
      <div className="flex">
        <div>
          <h2 style={{ marginBottom: "2px" }}>Which Area</h2>
          {window.cache.Areas.map((a) => <span id={'AreaSpan_' + a.Id} onClick={() => toggleSelectedArea(a.Id)} className={a.Id == newStockTakeLocation ? "ToggleItemEnabled" : "ToggleItemDisabled"}>{a.Name}</span>)}
          <div>
            <h2>Type</h2>
            <h3>Reconcilliation Types</h3>
            <div style={{ paddingBottom: "14px" }}>
              <span id='typeSpan_1' onClick={() => toggleSelectedStockTakeType(1)} className={newStockTakeType == 1 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>Full area stocktake</span>
              <span id='typeSpan_2' onClick={() => toggleSelectedStockTakeType(2)} className={newStockTakeType == 2 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>Partial - random locs.</span>
              <span id='typeSpan_3' onClick={() => toggleSelectedStockTakeType(3)} className={newStockTakeType == 3 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>Partial - selected locs.</span>
            </div>
            <h3>Seeding Types</h3>
            <div className="BottomTypes">
              {/*<span id='typeSpan_3' onClick={() => toggleSelectedStockTakeType(3)} className={newStockTakeType == 3 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>Partial - selected locs.</span>*/}
              <span id='typeSpan_4' onClick={() => toggleSelectedStockTakeType(4)} className={newStockTakeType == 4 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>Seed database</span>
              {
                window.cache.LoggedInAsDev ?
                    <>
                      <span id='typeSpan_5' onClick={() => toggleSelectedStockTakeType(5)} className={newStockTakeType == 5 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>DEV - Partial Seed</span>
                      <span id='typeSpan_6' onClick={() => toggleSelectedStockTakeType(6)} className={newStockTakeType == 6 ? "ToggleItemEnabled TypeSpan" : "ToggleItemDisabled TypeSpan"}>DEV - Specific Seed</span>
                    </>
                  : <></>
              }
            </div>
          </div>
          <div>
            <h2 style={{ marginBottom: "2px" }}>Stocktake Name</h2>
            <input className="TextBox Wide" style={{ width: "500px" }} id="txtNewSTName" />
          </div>
          <div className={newStockTakeType == 2 ? "Visible" : "Invisible"}>
            <h2>Locations</h2>
            Select this many random locations: <input className="TextBox SmallNumber" id="txtRandomLocs" />
          </div>
          <div className={newStockTakeType == 3 ? "Visible" : "Invisible"}>
            <h2 style={{ marginBottom: "2px" }}>Locations</h2>
            <div class="flex">
              <div class="ButtonLabelMargin">{newStocktakePickedLocs.length} locations currently selected.&nbsp;&nbsp;&nbsp;&nbsp;</div><div><TextButton Callback={selectLocations} Caption="Select Locations" Width={130} /></div>
            </div>
          </div>
          <div className={newStockTakeType == 5 ? "Visible" : "Invisible"}>
            <p>Devs only: seeding stocktake, 20 random locations</p>
          </div>
          <div className={newStockTakeType == 6 ? "Visible" : "Invisible"}>
            <p>Devs only: seeding stocktake, you choose locations</p>
            <div class="flex">
              <div class="ButtonLabelMargin">{newStocktakePickedLocs.length} locs. currently selected.&nbsp;&nbsp;&nbsp;</div>
              <div>
                <TextButton Callback={selectLocations} Caption="Select Locations" Width={130} />
              </div>
            </div>
          </div>
        </div>
        <div class="NewStockTakeRightPane">
          <div>
            {newStockTakeType == 1 || newStockTakeType == 2 || newStockTakeType == 4 || newStockTakeType == 5 ?
              <div>
                <h2>Selected locations:</h2>
                <div style={{ height: "40vh", minHeight: "40vh", overflowY: "scroll", fontSize: "20px" }}>
                  (This stocktake type does not require having locations selected by yourself)
                </div>
              </div>
              :
              <div>
                <h2>Selected locations:</h2>
                <div style={{ height: "40vh", minHeight: "40vh", width: "738px", minWidth: "738px", maxWidth: "738px", display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "baseline" }}>
                  {newStocktakePickedLocs.length === 0 ?
                    <span style={{ fontSize: "20px", color: "#FFAAAAFF" }}>This stocktake requires locations to be selected but none have been so far...</span>
                    :
                    newStocktakePickedLocs.map((l) => <span className="LocationAssigned">{l.Name}</span>)
                  }
                </div>
              </div>}
          </div>
          <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px", overflow: "hidden" }}>
            <div className={newStockTakeCreating ? "Visible flex" : "Invisible"}>
              <div><img src="Spinner.gif" width="130px" height="130px" /></div>
              <div class="NewStockTakePleaseWait">Creating a new stocktake based on the settings you have entered. This involves pulling locations and stock from SAS3 and may take a few seconds. Please wait...</div>
            </div>
          </div>
          <div className="flex" style={{ flexDirection: "row-reverse" }}>
            <div>
              <TextButton Width="130px" Caption="Cancel" Callback={props.ReturnToMain} IsCancel={true} />
            </div>
            <div style={{ paddingRight: "20px" }}>
              <TextButton Width="130px" Caption="Create stocktake" Callback={() => startNewStockTake()} IsSuccess={true} />
            </div>
          </div>
        </div>
      </div>
    </div>

    )

}

export default NewStocktake;