import React, { useState, useReducer, useEffect } from 'react';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import HumanInput from 'humaninput';
import { SubscriptionEvent } from '../backend_access/StateManager';
import TextButton from '../components/TextButton.js';
function Location(props) {

  const [updater, forceUpdate] = useReducer(x => x + 1, 0);
  const [localError, setLocalError] = useState('');
  const [localMessage, setLocalMessage] = useState('');

  const scanSubmit = () => {

    if (window.state.locationLookupInProgress) {
      return;
    }

    if (window.state.scanScreenInput === '') {
      return;
    }

    window.state.locationLookupInProgress = true;

    let req = { Token: window.state.apiToken, Id: window.state.scanScreenInput }
    if (window.cache.AdminRescanLocations != null && window.cache.AdminRescanLocations.length > 0 &&
      window.cache.AdminRescanLocations[0].IsMissingItemHunt) {
      req.IsMissingItemHunt = true;
    }

    if (req.Id.substring(0, 3).toLowerCase() === "loc") {
      req.Id = req.Id.substring(3);
      postAsync("GetStockTakeLocationBySASLocationId", req, submitLocationScanReturn)
    } else {
      window.state.locationLookupInProgress = false;
      window.state.itemLookupInProgress = false;
      window.state.scanScreenInput = '';
      window.communicationCode = 'BAD';
      setLocalError('That does not seem to be a location barcode!');
    }
       
  }

  const submitLocationScanReturnByName = (resp) => {

    window.state.locationLookupInProgress = false;
    window.state.itemLookupInProgress = false;
    window.state.scanScreenInput = '';

    var data = convertAsyncResponseToObject(resp);

    if (data.Result == undefined || data.Result == null || data.Result == false) {
      if (data.ErrorMessage == null) {
        window.communicationCode = 'BAD';
        setLocalError('Bad request. Are you sure you scanned/typed a location barcode?');
        return;
      }
      if (data.ErrorMessage.includes('is not part of the current')) {
        window.communicationCode = 'BAD';
        setLocalError(data.ErrorMessage);
        return;
      } else {
        window.communicationCode = 'BAD';
        setLocalError("An error occurred when accessing the backend of the system. Please report this to development. Error was: " + data.ErrorMessage);
        return;
      }
    }

    setLocalError('')
    window.communicationCode = 'GOOD';

    window.state.scanScreenLastLocationName = data.Data.Name
    window.state.scanScreenlastLocationId = data.Data.Id

    if (window.cache.AdminRescanLocations != null && window.cache.AdminRescanLocations.length && window.cache.AdminRescanLocations[0].IsMissingItemHunt) {
      markMissingItemLocation(data.Data.SASLocationId)
    } else {
      props.moveToItems();
    }

  }

  const submitLocationScanReturn = (resp) => {

    window.state.locationLookupInProgress = false;
    window.state.itemLookupInProgress = false;
    window.state.scanScreenInput = '';

    var data = convertAsyncResponseToObject(resp);

    if (data.Result == undefined || data.Result == null || data.Result == false) {
      if (data.ErrorMessage == null) {
        window.communicationCode = 'BAD';
        setLocalError('Bad request. Are you sure you scanned/typed a location barcode?');
        return;
      }
      if (data.ErrorMessage.includes('is not part of the current')) {
        window.communicationCode = 'BAD';
        setLocalError(data.ErrorMessage);
        return;
      } else {
        window.communicationCode = 'BAD';
        setLocalError("An error occurred when accessing the backend of the system. Please report this to development. Error was: " + data.ErrorMessage);
        return;
      }
    }

    setLocalError('')
    window.communicationCode = 'GOOD';

    window.state.scanScreenLastLocationName = data.Data.Name
    window.state.scanScreenlastLocationId = data.Data.Id

    if (window.cache.AdminRescanLocations != null && window.cache.AdminRescanLocations.length && window.cache.AdminRescanLocations[0].IsMissingItemHunt) {
      markMissingItemLocation(data.Data.SASLocationId)
    } else {
      props.moveToItems();
    }

  }

  const markMissingItemLocation = (loc) => {

    var request = {}
    request.Token = window.state.apiToken
    request.StockUnitId = window.cache.AdminRescanLocations[0].MissingItemStockUnitId
    request.LocationId = loc
    postAndWait('MarkItemFoundLocation', request)
    setLocalMessage('Found in location ' + window.state.scanScreenLastLocationName)
    setTimeout(clearLocalInfo, 4000);
    refresh()

  }

  const refresh = () => {
    forceUpdate();
  }

  const clearLocalInfo = () => {
    setLocalMessage('')
  }


  const keyUp = (event, key, code) => {

    if (code != 'Enter' && (code.length == 1 || code == "Equal")) {
      window.state.scanScreenInput += key;
    }
    if (code == 'Enter') {
      scanSubmit();
    }
  }

  if (window.state.humanInput == null) {

    eval('window.__VERSION__ = 0')
    var hi = new HumanInput(window)

    hi.off('keydown')
    hi.on('keydown', keyUp)
    hi.filter = (e) => { return true }

    window.state.humanInput = hi;
    window.state.locationLookupInProgress = false;

  } else {

    window.state.humanInput.off('keydown')
    window.state.humanInput.on('keydown', keyUp)
    window.state.humanInput.filter = (e) => { return true }

  }

  useEffect(() => {

    return () => {
      window.state.unsubscribeFromEvent('Locations', SubscriptionEvent.AdminLocationsToScanUpdated);
      window.state.unsubscribeFromEvent('Locations', SubscriptionEvent.MyLocationsToScanUpdated);
    }

  }, [])

  window.state.subscribeToEvent('Locations', SubscriptionEvent.MyLocationsToScanUpdated, refresh);
  window.state.subscribeToEvent('Locations', SubscriptionEvent.AdminLocationsToScanUpdated, refresh);

  return (
    <div className="PortraitCenter">
      {window.cache.StocktakeInProgress ?
        window.cache.StocktakeStatus == 1 ?
          <div>
            <div className="PortraitCenter">A Stocktake is ongoing!</div>
            <div className="PortraitCenter">
              <div className="ReconcilliationNextScanLabel">Stocktake in main stage: <br/>normal scans against SAS3</div>
              <h3 style={{ marginTop: "19px", marginBottom: "13px" }}>My next location to scan:</h3>
              <p className="NextLocation" style={{ marginBottom: "21px" }}>{window.cache.MyLocationsToScan.length > 0 ? window.cache.MyLocationsToScan[0] 
                : 'None assigned right now'}</p>
              {window.cache.MyLocationsToScan.length > 1 ? <h4 style={{ marginTop: "-18px", marginBottom: "5px" }}>And then...</h4> : <></>}
              {window.cache.MyLocationsToScan.length > 1 ? <div>{window.cache.MyLocationsToScan[1]}</div> : <></>}
              {window.cache.MyLocationsToScan.length > 2 ? <div>{window.cache.MyLocationsToScan[2]}</div> : <></>}
              {window.cache.MyLocationsToScan.length > 3 ? <div>{window.cache.MyLocationsToScan[3]}</div> : <></>}
            </div>
            <p>&nbsp;</p>
            <div className="blink_me">Awaiting a location scan...</div>
          </div>
          :
          <div>
            <div className="PortraitCenter"><span className="PortraitHeader">Mode: Reconcilliation Scanning</span></div>
            <div className="PortraitCenter">
              <div className="ReconcilliationNextScanLabel">Reconcilliation stage:<br/>Admin requested scans</div>
              <h3 style={{ marginTop: "19px", marginBottom: "13px" }}>My next location to scan:</h3>
              <p className="NextLocation" style={{ marginBottom: "21px" }}>{window.cache.AdminRescanLocations.length > 0 ? window.cache.AdminRescanLocations[0].LocationName + ' ' + window.cache.AdminRescanLocations[0].Who : 'None assigned right now'}</p>
              {window.cache.AdminRescanLocations.length > 1 ? <h4 style={{ marginTop: "-18px", marginBottom: "5px" }}>And then...</h4> : <></>}
              {window.cache.AdminRescanLocations.length > 1 ? <div>{window.cache.AdminRescanLocations[1].LocationName + ' ' + window.cache.AdminRescanLocations[1].Who}</div> : <></>}
              {window.cache.AdminRescanLocations.length > 2 ? <div>{window.cache.AdminRescanLocations[2].LocationName + ' ' + window.cache.AdminRescanLocations[2].Who}</div> : <></>}
              {window.cache.AdminRescanLocations.length > 3 ? <div>{window.cache.AdminRescanLocations[3].LocationName + ' ' + window.cache.AdminRescanLocations[3].Who}</div> : <></>}
              {window.cache.AdminRescanLocations.length === 1 && window.cache.AdminRescanLocations[0].IsMissingItemHunt ? <div style={{ color: "#00F1FF", fontWeight: "600" }}>This is a missing item hunt, item<br />was expected in {window.cache.AdminRescanLocations[0].LocationName}</div> : <></>}
            </div>
            {window.cache.AdminRescanLocations.length === 1 && window.cache.AdminRescanLocations[0].IsMissingItemHunt ?
              <div className="PortraitCenter" style={{ paddingTop: "12px" }}>
                <div class="flex">
                  <div style={{ width: "80px" }}>Part No:</div>
                  <div>{window.cache.AdminRescanLocations[0].PartNo}</div>
                </div>
                <div class="flex" style={{ paddingBottom: "20px" }}>
                  <div style={{ width: "80px" }}>Serial:</div>
                  <div>{window.cache.AdminRescanLocations[0].SerialNo}</div>
                </div>
                <div className="blink_me">Scan new location if you've found it...</div>
                <div style={{ paddingTop: "28px" }}>
                  <TextButton Caption="I can't find it!" Width="140px" IsCancel={true} />
                </div>
              </div>
              :
              <div className="blink_me">Awaiting a location scan...</div>}
          </div>
        :
        <div style={{ color: '#FF7777', fontSize: '17px' }}>There is no ongoing stocktake right now!</div>
      }
      {props.scanMessage != '' ?
        <div className="SubmitScanSuccess" style={{ maxWidth: "320px" }}>{props.scanMessage}</div> : <></>}
      {props.scanError != '' ?
        <div className="ScanError" style={{ maxWidth: "320px", fontSize: "16px" }}>{props.scanMessage}</div> : <></>}
      {localError != '' ?
        <div className="ScanError" style={{ maxWidth: "320px", fontSize: "16px" }}>{localError}</div> : <></>}
      {localMessage != '' ?
        <div className="SubmitScanSuccess" style={{ maxWidth: "320px" }}>{localMessage}</div> : <></>}
    </div>
  )

}

export default Location;
