import React, { useState, useReducer, useEffect } from 'react';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import { showError, showInfo, showYesNoQuestion } from '../components/PopupMessages.js';
import MainButton from '../components/MainButton';
import TextButton from '../components/TextButton.js';
import NumberPad from '../components/NumberPad.js';
import SmallNumberPad from '../components/SmallNumberPad.js';
import HumanInput from 'humaninput';
function PurchaseOrder(props) {

  const [updater, forceUpdate] = useReducer(x => x + 1, 0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [scannedIds, setScannedIds] = useState([]);
  const [serialString, setSerialString] = useState('No serials scanned yet...');
  const [serialList, setSerialList] = useState([]);
  const [serialCount, setSerialCount] = useState(0);
  const [countedValue, setCountedValue] = useState(0);

  const selectPartNo = (p) => {
    setSelectedItem(p);
    if (!p.NeedsSerial) {
      for (var nn = 0; nn < scannedIds.length; nn++) {
        if (scannedIds[nn].NonSerialCount != null && scannedIds[nn].NonSerialCount > 0) {
          enableButtons();
          return;
        }
      }
      disableButtons();
    } else {
      enableButtons();
    }
  }

  function updateCountAndList() {
    var found = false;
    if (selectedItem == null) {
      return;
    }

    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id === selectedItem.Id) {
        if (selectedItem.NeedsSerial) {
          setSerialString(scannedIds[nn].Serials.join(','));
          setSerialCount(scannedIds[nn].Serials.length);
          setCountedValue(0);
          if (scannedIds[nn].Serials.length > 0) {
            setSerialList(scannedIds[nn].Serials)
          }
        } else {
          setCountedValue(scannedIds[nn].NonSerialCount)
        }
        found = true;
      }
    }

    if (!found) {
      setSerialString('No serials scanned yet...')
      setSerialCount(0)
      setCountedValue(0)
    }

  }

  function hasSerials(id) {
    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id === id) {
        return true;
      }
    }
    return false;
  }

  const scanSubmit = () => {

    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Serials != null) {
        for (var ns = 0; ns < scannedIds[nn].Serials.length; ns++) {
          if (scannedIds[nn].Serials[ns] === window.state.scanScreenInput) {
            window.state.scanScreenInput = '';
            window.communicationCode = 'DINGDONG';
            showError('You have already scanned that serial number here!')
            return;
          }
        }
      }
    }

    var existing = []
    var current = null;
    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id != selectedItem.Id) {
        existing.push(scannedIds[nn]);
      } else {
        current = scannedIds[nn];
      }
    }

    if (current === null) {
      current = {}
      current.Id = selectedItem.Id;
      current.PartNo = selectedItem.PartNo;
      current.Serials = []
    }

    var temp = []
    temp.push(window.state.scanScreenInput)
    window.state.scanScreenInput = '';

    for (var nn = 0; nn < current.Serials.length; nn++) {
      temp.push(current.Serials[nn])
    }
    current.Serials = temp;    

    existing.push(current);
    setScannedIds(existing);

    window.communicationCode = 'GOOD';
    setSerialList(current.Serials)
    enableButtons();
    
  }

  const removeSerial = (ser) => {

    var existing = []
    var current = null;
    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id != selectedItem.Id) {
        existing.push(scannedIds[nn]);
      } else {
        current = scannedIds[nn];
      }
    }

    if (current === null) {
      showError("Nothing is selected");
    } else {
      var newList = []
      for (var nn = 0; nn < current.Serials.length; nn++) {
        if (current.Serials[nn] != ser) {
          newList.push(current.Serials[nn])
        }
      }
      current.Serials = newList;
      if (current.Serials.length > 0) {
        existing.push(current);
      }
      setScannedIds(existing);
      window.communicationCode = 'DINGDONG';
      setSerialList(current.Serials)
    }



  }

  const beginCancel = () => {
    showYesNoQuestion('Are you sure you wish to discard all these scans and return to previous screen?', finishCancel);
  }

  const finishCancel = () => {
    if (window.state.scanScreenStartingData != null) {
      window.state.scanModeLocationScanItems = window.state.scanScreenStartingData
    }
    props.cancelScans();
  }

  const beginConfirm = () => {
    if (scannedIds == null || scannedIds.length == 0) {
      window.communicationCode = 'BAD';
      showError("Nothing has been scanned. If this location is empty, cancel this screen and use the 'Its Empty' button on previous screen");
      return;
    }

    var existing = window.state.scanModeLocationScanItems;
    if (existing.ItemsWithSerials == null) {
      existing.ItemsWithSerials = [];
    }
    if (existing.ItemsWithoutSerials == null) {
      existing.ItemsWithoutSerials = [];
    }
    if (existing.StockUnits == null) {
      existing.StockUnits = [];
    }

    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Serials != null) {
        for (var ns = 0; ns < scannedIds[nn].Serials.length; ns++) {
          var newObj = {}
          newObj.PONum = window.state.scanModePONumber;
          newObj.PartNo = scannedIds[nn].PartNo;
          newObj.Serial = scannedIds[nn].Serials[ns];
          newObj.POItemId = scannedIds[nn].Id;
          existing.ItemsWithSerials.push(newObj);
        }
      } else {
        var newObj = {}
        newObj.PONum = window.state.scanModePONumber;
        newObj.PartNo = scannedIds[nn].PartNo;
        newObj.POItemId = scannedIds[nn].Id;
        newObj.Count = scannedIds[nn].NonSerialCount;
        existing.ItemsWithoutSerials.push(newObj);
      }
    }

    window.state.scanModeLocationScanItems = existing;
    props.cancelScans();

  }

  const numberEntered = (val) => {

    var existing = []
    var current = null;
    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id != selectedItem.Id) {
        existing.push(scannedIds[nn]);
      } else {
        current = scannedIds[nn];
      }
    }

    if (current === null) {
      current = {}
      current.Id = selectedItem.Id;
      current.PartNo = selectedItem.PartNo;
      current.NonSerialCount = val;
    }

    current.NonSerialCount = val;

    existing.push(current);
    setScannedIds(existing);
    enableButtons();

  }

  const recount = () => {

    var existing = []
    var current = null;
    for (var nn = 0; nn < scannedIds.length; nn++) {
      if (scannedIds[nn].Id != selectedItem.Id) {
        existing.push(scannedIds[nn]);
      } else {
        current = scannedIds[nn];
      }
    }

    current.NonSerialCount = 0;

    existing.push(current);
    setScannedIds(existing);

    setCountedValue(0)
    disableButtons();

  }

  const finishConfirm = () => {



  }

  const keyUp = (event, key, code) => {

    if (code != 'Enter' && (code.length == 1 || code == "Equal")) {
      window.state.scanScreenInput += key;
    }
    if (code == 'Enter') {
      scanSubmit();
    }
  }

  if (window.state.humanInput == null) {

    eval('window.__VERSION__ = 0')
    var hi = new HumanInput(window)

    hi.off('keydown')
    hi.on('keydown', keyUp)
    hi.filter = (e) => { return true }

    window.state.humanInput = hi;
    window.state.locationLookupInProgress = false;

  } else {

    window.state.humanInput.off('keydown')
    window.state.humanInput.on('keydown', keyUp)
    window.state.humanInput.filter = (e) => { return true }

  }

  console.log('')

  if (window.state.keepPOItems) {

    console.log('aa')

    window.state.keepPOItems = false;
    window.state.scanScreenStartingData = window.state.scanModeLocationScanItems;

    var existingData = []
    var seenIds = []
    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithSerials.length; nn++) {
      if (window.state.scanModeLocationScanItems.ItemsWithSerials[nn].PONum == window.state.scanModePONumber) {
        if (seenIds.includes(window.state.scanModeLocationScanItems.ItemsWithSerials[nn].POItemId)) {
          for (var nI = 0; nI < existingData.length; nI++) {
            if (existingData[nI].Id == window.state.scanModeLocationScanItems.ItemsWithSerials[nn].POItemId) {
              existingData[nI].Serials.push(window.state.scanModeLocationScanItems.ItemsWithSerials[nn].Serial);
              break;
            }
          }
        } else {
          var item = {}
          item.Id = window.state.scanModeLocationScanItems.ItemsWithSerials[nn].POItemId
          item.PartNo = window.state.scanModeLocationScanItems.ItemsWithSerials[nn].PartNo
          item.Serials = []
          item.Serials.push(window.state.scanModeLocationScanItems.ItemsWithSerials[nn].Serial)
          seenIds.push(window.state.scanModeLocationScanItems.ItemsWithSerials[nn].POItemId)
          existingData.push(item)
        }
      }
    }

    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithoutSerials.length; nn++) {
      if (window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn].PONum == window.state.scanModePONumber) {
        var item = {}
        item.Id = window.state.scanModeLocationScanItems.ItemsWithSerials[nn].POItemId
        item.PartNo = window.state.scanModeLocationScanItems.ItemsWithSerials[nn].PartNo
        item.NonSerialCount = window.state.scanModeLocationScanItems.ItemsWithSerials[nn].Count
        existingData.push(item)
      }
    }

    console.log(existingData)
    setScannedIds(existingData)

    var existingWithSerial = []
    var existingWithoutSerial = []
    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithSerials.length; nn++) {
      if (window.state.scanModeLocationScanItems.ItemsWithSerials[nn].PONum != window.state.scanModePONumber) {
        existingWithSerial.push(window.state.scanModeLocationScanItems.ItemsWithSerials[nn])
      }
    }
    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithoutSerials.length; nn++) {
      if (window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn].PONum != window.state.scanModePONumber) {
        existingWithoutSerial.push(window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn])
      }
    }
    window.state.scanModeLocationScanItems.ItemsWithSerials = existingWithSerial;
    window.state.scanModeLocationScanItems.ItemsWithoutSerials = existingWithoutSerial;


  } else {
    window.state.scanScreenStartingData = null
  }

  useEffect(() => {
    forceUpdate();
  }, [serialString])

  useEffect(() => {
    updateCountAndList();
  }, [scannedIds])

  useEffect(() => {
    updateCountAndList();
  }, [selectedItem])

  return (

    <div className="PortraitCenter">
      <div style={{ marginTop: "-20px" }}>
        <h2>Location: {window.state.scanScreenLastLocationName}, PO: {window.state.scanModePONumber}</h2>
      </div>
      <div style={{ marginTop: "-10px" }}>
        <h3>Part Nos on PO:</h3>
      </div>
      <div style={{ marginTop: "-12px", display: "flex", flexDirection: "row", alignContent: "baseline", width: "100%", overflowX: "scroll", height: "64px", maxHeight: "64px" }}>
        {window.state.scanModePOItems.map((p => <span onClick={() => selectPartNo(p)} className={selectedItem != null && p.Id == selectedItem.Id ?
          "PortraitSelectedLocation" : hasSerials(p.Id) ? "PortraitUnselectedLocationWithScans" : "PortraitUnselectedLocation"} > {p.PartNo}</span>))}
      </div>
      <span style={{ fontSize: "11px", paddingLeft: "4px" }}>There are {window.state.scanModePOItems.length} PartNos, scroll to see all if needed</span>
      {selectedItem != null && selectedItem.IsDuplicatedOnPO ?
        <div style={{ fontSize: "12px", color: "#DD7777FF", paddingLeft: "4px" }}>Duplicate PartNo on PO. Check description fully!</div>
        :
        <div style={{ fontSize: "12px", paddingLeft: "4px" }} >&nbsp;</div>
      }
      {selectedItem != null ?
        <div style={{ paddingTop: "5px", height: "239px", minHeight: "239px", maxHeight: "239px" }}>
          <div style={{ marginLeft: "5px", marginTop: "5px", height: "62px", maxHeight: "62px", overflowY: "hidden", color: "rgb(247 170 73)" }}>
            <div style={{ color: "rgb(247 170 73)", fontSize: "17px" }}>{selectedItem.PartNo}</div>
            <div style={{ color: "#FFFFFFFF", fontSize: "11px" }}>{selectedItem.Description}</div>
          </div>
          {selectedItem.NeedsSerial ?
            <div>
              <div style={{ height: "65px", minHeight: "65px", maxHeight: "65px" }}>
                <div style={{ marginLeft: "5px", marginTop: "8px" }} className="blink_me" > Scan all serials...</div>
              </div>
              <div>
                <div style={{ marginTop: "-12px", display: "flex", flexDirection: "row", alignContent: "baseline", width: "100%", overflowX: "scroll", height: "64px", maxHeight: "64px" }}>
                  {serialList.map((ser =>
                    <div class="flex PortraitSerialBlock"><div onClick={() => removeSerial(ser)} style={{ fontWeight: "600", marginTop: "-5px", fontSize: "22px", color: "#FF3333" }}>X</div>&nbsp;&nbsp;<div>{ser}</div></div>
                  ))}
                </div>
              </div>
              <div style={{ paddingTop: "3px" }}>
                Scanned: {serialList.length}
              </div>
            </div>
            :
            <div style={{ marginTop: "-3px" }}>
              {countedValue == 0 ?
                <SmallNumberPad SubmitCallback={numberEntered} />
                :
                <div style={{ paddingLeft: "4px", paddingTop: "30px" }}>
                  Counted items: {countedValue}
                  <div style={{ paddingTop: "8px" }}>
                    <TextButton Callback={recount} Caption="Recount items" Width="140px" />
                  </div>
                </div>}
            </div>
          }
        </div>        :
        <div style={{ paddingTop: "5px", height: "239px", minHeight: "239px", maxHeight: "239px" }}>
          <i>Select PartNo to begin serial scanning</i>
        </div>}
      <div className="flex" style={{ paddingTop: "5px" }}>
        <div style={{ paddingRight: "8px", paddingLeft: "4px" }}>
          <TextButton DivId="cancelScan" IsCancel={true} Caption="Cancel scan" Callback={beginCancel} Width="105px" />
        </div>
        <div>
          <TextButton DivId="approveScan" IsSuccess={true} Caption="Confirm items" Callback={beginConfirm} Width="105px" />
        </div>
      </div>
    </div>
  )

}

function disableButtons() {
  document.getElementById('approveScan').className = 'disablebtn'
}

function enableButtons() {
  document.getElementById('approveScan').className = 'successbtn'
}

export default PurchaseOrder;