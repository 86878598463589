function RecentScans(props) {

  return (

    <div className="RecentScan">
      <span class="RecentScanItem">{props.Number}&nbsp;x&nbsp;{props.Description.length > 64 ? props.Description.substring(0, 64) + '...' : props.Description}</span><br />Scanned by: {props.Who}<br />
      Serial No: {props.SerialNo}&nbsp;&nbsp;&nbsp;&nbsp;Location: {props.Location}
    </div>

  )

}

export default RecentScans;