import { postAndWait, processAPIResponse, postAsync } from './APIAccess';
import { SubscriptionEvent, Dictionary } from './StateManager';

export function loadInitialCache() {

  window.cache = {
    StocktakeInProgress: null,
    Areas: null,
    OngoingStocktakeStats: null,
    LikelyScanners: null,
    MostRecentFinishedStockTake: null
  };

  window.communicationCode = '';

  var request = { Token: window.state.apiToken };

  var isLoggedInAsDev = postAndWait('LoggedInAsDeveloper', request);
  window.cache.LoggedInAsDev = isLoggedInAsDev.Data;

  var isInProgress = postAndWait('StocktakeInProgress', request);
  window.cache.StocktakeInProgress = isInProgress.Data;

  var areas = postAndWait('GetAreas', request);
  window.cache.Areas = areas.Data;

  var likelyScanners = postAndWait('GetLikelyScanners', request);
  window.cache.LikelyScanners = likelyScanners.Data;

  var mostRecentFinished = postAndWait('GetLastCompletedStocktake', request);
  window.cache.MostRecentFinishedStockTake = mostRecentFinished.Data;

  if (isInProgress.Data) {

    var stats = postAndWait('GetCurrentStockTakeStats', request)
    window.cache.OngoingStocktakeStats = stats.Data;

    var stLocs = postAndWait('GetAllLocationsForStocktake', request)
    window.cache.AllLocations = stLocs.Data;

    var myLocs = postAndWait('GetMyNextAssignedLocations', request)
    window.cache.MyLocationsToScan = myLocs.Data;

    var rescanLocs = postAndWait('GetAdminRequestedRescans', request)
    window.cache.AdminRescanLocations = rescanLocs.Data;

    var status = postAndWait('GetCurrentStocktakeStatus', request)
    window.cache.StocktakeStatus = status.Data;

    var allocatedStats = postAndWait('GetAllocatedScanStats', request)
    window.cache.AllocatedScanStats = allocatedStats.Data;

    var recData = postAndWait('GetReconcilliationData', request)
    if (recData.Data != null) {
      window.cache.RecData = recData.Data.Locations;
    }

  }

  setTimeout(fastCacheRefresh, 2500);
  setTimeout(slowCacheRefresh, 6000);

}

function fastCacheRefresh() {

  refreshStockTakeInProgressCacheAsync();
  refreshMyLocationsToScanCacheAsync();
  refreshAdminLocationsToScanCacheAsync();
  if (window.state.scanningOnlyMode == false) {
    refreshStockTakeStatsCacheAsync();
  }

  setTimeout(fastCacheRefresh, 2500);

}

function slowCacheRefresh() {
  if (window.state.scanningOnlyMode == false) {
    refreshReconcilliationDataCacheAsync();
  }
  setTimeout(slowCacheRefresh, 6000);
}


export function refreshStockTakeInProgressCacheAsync() {
    var request = { Token: window.state.apiToken };
    postAsync("StocktakeInProgress", request, stockTakeInProgressCacheAsyncCallback)
}

export function refreshStockTakeStatsCacheAsync() {
  if (window.cache.StocktakeInProgress) {
    var request = { Token: window.state.apiToken };
    postAsync("GetCurrentStockTakeStats", request, stockTakeStatsCacheAsyncCallback)
  }
}

export function refreshMyLocationsToScanCacheAsync() {
  var request = { Token: window.state.apiToken };
  postAsync("GetMyNextAssignedLocations", request, myLocationsToScanCacheAsyncCallback)
}

export function refreshAdminLocationsToScanCacheAsync() {
  var request = { Token: window.state.apiToken };
  postAsync("GetAdminRequestedRescans", request, adminLocationToScanCacheAsyncCallback)
}

export function refreshStocktakeStatusCacheAsync() {
  if (window.cache.StocktakeInProgress) {
    var request = { Token: window.state.apiToken };
    postAsync("GetCurrentStocktakeStatus", request, stocktakeStatusCacheAsyncCallback)
  }
}

export function refreshReconcilliationDataCacheAsync() {
  var request = { Token: window.state.apiToken };
  postAsync("GetReconcilliationData", request, reconcilliationDataCacheAsyncCallback)
}

export function refeshLocationAllocatedStats() {
  if (window.cache.StocktakeInProgress) {
    var request = { Token: window.state.apiToken };
    postAsync("GetAllocatedScanStats", request, allocatedScanStatsCacheAsyncCallback)
  }
}



export function forceImmediateStockTakeInProgressCacheRefresh() {
  var request = { Token: window.state.apiToken };
  var isInProgress = postAndWait('StocktakeInProgress', request);
  window.cache.StocktakeInProgress = isInProgress.Data;
}

export function forceImmediateStockTakeStatsRefresh() {
  var request = { Token: window.state.apiToken };
  var isInProgress = postAndWait('GetCurrentStockTakeStats', request);
  window.cache.OngoingStocktakeStats = isInProgress.Data;
}

export function forceImmediateMyLocationRefresh() {
  var request = { Token: window.state.apiToken };
  var myLocs = postAndWait('GetMyNextAssignedLocations', request)
  window.cache.MyLocationsToScan = myLocs.Data;
}

export function forceImmediateAllLocationsRefresh() {
  var request = { Token: window.state.apiToken };
  var stLocs = postAndWait('GetAllLocationsForStocktake', request)
  window.cache.AllLocations = stLocs.Data;
}

export function forceImmediateReconcilliationDataRefresh() {
  var request = { Token: window.state.apiToken };
  var dat = postAndWait('GetReconcilliationData', request)
  window.cache.RecData = dat.Data.Locations;
}

export function forceImmediateStatusRefresh() {

  var request = { Token: window.state.apiToken };

  var isInProgress = postAndWait('StocktakeInProgress', request);
  window.cache.StocktakeInProgress = isInProgress.Data;

  var status = postAndWait('GetCurrentStocktakeStatus', request)
  window.cache.StocktakeStatus = status.Data;

}

export function forceImmediateAllocatedScanStatsRefresh() {
  var request = { Token: window.state.apiToken };
  var allocatedStats = postAndWait('GetAllocatedScanStats', request)
  window.cache.AllocatedScanStats = allocatedStats.Data;
}

export function forcePOScreenOpenRefresh() {
  window.state.fireEvent(SubscriptionEvent.POScanScreenCancelled)
}

export function forceSerialScreenOpenRefresh() {
  window.state.fireEvent(SubscriptionEvent.SerialScanScreenCancelled)
}

function stockTakeInProgressCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.StocktakeInProgress = resp.Data;
  window.state.fireEvent(SubscriptionEvent.StockTakeInProgressUpdated)
}

function stockTakeStatsCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.OngoingStocktakeStats = resp.Data;
  window.state.fireEvent(SubscriptionEvent.StockTakeStatsUpdated)
}

function myLocationsToScanCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.MyLocationsToScan = resp.Data;
  window.state.fireEvent(SubscriptionEvent.MyLocationsToScanUpdated)
}

function adminLocationToScanCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.AdminRescanLocations = resp.Data;
  if (resp.Data.length == 1 && resp.Data[0].IsMissingItemHunt) {
    window.state.nextScanIsMissingItemHunt = true
  } else {
    window.state.nextScanIsMissingItemHunt = false
  }
  window.state.fireEvent(SubscriptionEvent.AdminLocationsToScanUpdated)
}

function stocktakeStatusCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.StocktakeStatus = resp.Data;
  window.state.fireEvent(SubscriptionEvent.StocktakeStatusUpdated)
}

function reconcilliationDataCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  if (resp.Data != null && resp.Data.Locations != null) {
    window.cache.RecData = resp.Data.Locations;
  }
  window.state.fireEvent(SubscriptionEvent.ReconcilliationDataUpdated)
}

function allocatedScanStatsCacheAsyncCallback(response) {
  var resp = JSON.parse(response.currentTarget.response);
  window.cache.AllocatedScanStats = resp.Data;
}




